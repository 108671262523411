module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://use.typekit.net"],"web":[{"name":"roc-grotesk","file":"https://use.typekit.net/amg5jos.css"},{"name":"roc-grotesk-wide","file":"https://use.typekit.net/amg5jos.css"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"55ee5420384598dffbc87626853d6d7e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"nqNyKFrjChkGLsH1A5E5TpV9mFeP18sD","devKey":"YSE7BdzSFBRcBuTC7KUQhZoTstNbKd0F","trackPage":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
