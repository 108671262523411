export const handleDarkModeWithLocalStorageAndUserPreference = () => {
  const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
  function updateMode() {
    const e = darkModeMediaQuery.matches
    const t =
      window.localStorage.isDarkMode === 'true' ||
      (!('isDarkMode' in window.localStorage) && e)
    if (t) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
    // Always delete 'isDarkMode' from localStorage if it matches the media query
    if (window.localStorage.isDarkMode === String(e)) {
      delete window.localStorage.isDarkMode
    }
  }
  function disableTransitionsTemporarily() {
    document.documentElement.classList.add('[&_*]:!transition-none')
    window.setTimeout(() => {
      document.documentElement.classList.remove('[&_*]:!transition-none')
    }, 0)
  }
  function updateModeWithoutTransitions() {
    disableTransitionsTemporarily()
    updateMode()
  }
  updateMode()
  darkModeMediaQuery.addEventListener('change', updateModeWithoutTransitions)
  window.addEventListener('storage', (event) => {
    // Only update mode if 'isDarkMode' in localStorage changes
    if (event.key === 'isDarkMode') {
      updateModeWithoutTransitions()
    }
  })
}
